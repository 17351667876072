<template>
	<v-container fluid>
		<please-select-utility>
			<v-row>
				<v-col cols="12" class="d-flex">
					<session-select
						:utility="selectedUtility"
						v-model="selectedSession"
						dense outlined hide-details single-line label="Сесия"
						style="max-width: 400px"
						class="mr-2"
						clearable
					/>
					<v-spacer/>
				</v-col>
			</v-row>
			<v-row v-if="selectedUtility && selectedSession">
				<v-col>
					<search @filter="filter = $event" :imodel="imodel" />
					<list
						ref="dataTable"
						hide-edit hide-select hide-delete
						:imodel="imodel"
						:filter="filter"
						:params="{utility:selectedUtility,session:selectedSession}"
						headers-skip="notes,izr_json,utility_import_json"
						hidden-cols="station_id"
						fixed-header
						height="500"
					>
						<template #item.settings_slujeben="{value}">
							<div v-if="value" style="white-space: nowrap">
								<span v-if="value.toplomer.correction">Т:{{ value.toplomer.correction }}% </span>
								<span v-if="value.vodomer.correction">В:{{ value.vodomer.correction }}% </span>
								<span v-if="value.radiator.correction">Р:{{ value.radiator.correction }}% </span>
							</div>
						</template>
						<template #item.qMax_devices="{value}">
							<v-chip color="green lighten-2" v-if="value" label small>Да</v-chip>
						</template>
						<template #right-actions="{item}">
							<imot-details-link
								target-blank
								:utility="utility"
								:session="session"
								:station="item.station_id"
							/>
						</template>
					</list>
				</v-col>
			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import List from "@/ittijs/List";
import Model from "./Model";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import SessionSelect from "@/views/sessions/SessionSelect";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import Search from "@/ittijs/Search";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";

const model = new Model;

export default {
	components: {
		ImotDetailsLink,
		Search,
		PleaseSelectUtility,
		SessionSelect,
		List,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
			session: 'selectedSession',
		}),
	],
	props: [
		'utility',
		'session',
	],
	data(){
		return {
			selectedSession: null,
			imodel: model,
			filter: {},
		}
	},
}
</script>